<template>
  <app-modal-transaction-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :transaction="selectTransaction"
    @update:transaction="selectTransaction = $event"
    routeName="balance.transaction.payment-link"
  />
  <app-table
    :apiResponse="apiPaginationResponse?.data ?? []"
    :loading="loading"
    :showEmptyButton="false"
    :filters="
      this.isMasterBusiness
        ? [
            {
              key: 'filter[business_id]',
              type: 'select',
              placeholder: $t('business.table.header_businss_name'),
              items: all_business,
            },
            {
              key: 'filter[id]',
              type: 'text',
              placeholder: 'ID',
              value: '',
            },
            {
              key: 'filter[amount]',
              type: 'text',
              placeholder: 'Amount',
              value: '',
            },
            {
              key: 'filter[type]',
              type: 'select',
              placeholder: 'Type',
              items: [
                {
                  id: 'transfer_in',
                  name: $t('wallet.transaction.type_transfer_in'),
                },
                {
                  id: 'transfer_out',
                  name: $t('wallet.transaction.type_transfer_out'),
                },
              ],
            },
            {
              key: 'filter[created_at_between]',
              type: 'date',
              placeholder: 'Date',
              value: '',
            },
          ]
        : [
            {
              key: 'filter[id]',
              type: 'text',
              placeholder: 'ID',
              value: '',
            },
            {
              key: 'filter[amount]',
              type: 'text',
              placeholder: 'Amount',
              value: '',
            },
            {
              key: 'filter[type]',
              type: 'select',
              placeholder: 'Type',
              items: [
                {
                  id: 'transfer_in',
                  name: $t('wallet.transaction.type_transfer_in'),
                },
                {
                  id: 'transfer_out',
                  name: $t('wallet.transaction.type_transfer_out'),
                },
              ],
            },
            {
              key: 'filter[created_at_between]',
              type: 'date',
              placeholder: 'Date',
              value: '',
            },
          ]
    "
    @params-changed="paramsChanged"
    @on-item-click="onItemClick"
  >
    <template v-slot:header>
      <th class="font-bold">{{ $t("wallet.transaction.id") }}</th>
      <th v-if="this.isMasterBusiness" class="font-bold">
        {{ $t("wallet.transaction.business_name") }}
      </th>
      <th class="font-bold">{{ $t("wallet.transaction.amount") }}</th>
      <th class="font-bold">{{ $t("wallet.transaction.type") }}</th>
      <th class="font-bold">{{ $t("wallet.transaction.date") }}</th>
    </template>
    <template v-slot:body="slotData">
      <td class="">
        {{ slotData.model._id }}
      </td>
      <td v-if="this.isMasterBusiness">
        {{ slotData.model.business.name }}
      </td>
      <td class="flex place-items-center space-x-2 justify-between">
        <p
          class="font-medium"
          :class="{
            'text-success': slotData.model.type?.includes('_in'),
            'text-error': slotData.model.type?.includes('_out'),
          }"
        >
          {{
            $formats.currency(
              slotData.model.currency,
              slotData.model.amount ?? 0.0
            )
          }}
        </p>
      </td>
      <td>
        <p class="capitalize">
          {{ slotData.model.type?.replace("_", " ") }}
        </p>
      </td>
      <td>
        {{ $moment(slotData.model.updated_at).format("DD MMM YYYY hh:mm a") }}
      </td>
    </template>
  </app-table>
</template>
<script>
import Role from "@/utils/const/role";
import BUSINESS_STATUS from "@/utils/const/business_status";

export default {
  data() {
    return {
      filters: "",
      showModalDetail: false,
      selectTransaction: null,
      checkRoleMasterBusiness: [
        Role.MASTER_BUSINESS_OWNER,
        Role.MASTER_BUSINESS_ADMIN,
        Role.MASTER_BUSINESS_MANAGER,
        Role.MASTER_BUSINESS_DEVELOP,
        Role.MASTER_BUSINESS_SUPPORT,
      ],
    };
  },

  mounted() {
    this.$store.dispatch(
      "walletStore/retrieveWalletTransactions",
      this.filters
    );
    this.$store.dispatch("masterBusinessStore/getMasterBusiness", {
      business_status_id: BUSINESS_STATUS.APPROVED,
    });
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["walletStore/apiPaginationResponse"];
    },

    loading() {
      return this.$store.getters["walletStore/loading"];
    },

    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },

    isMasterBusiness() {
      return this.checkRoleMasterBusiness.includes(this.selectedRole?.role_id);
    },

    all_business() {
      return this.$store.getters["masterBusinessStore/all_business"];
    },
  },

  methods: {
    paramsChanged(filters, queryParams) {
      this.filters = filters;
      if (this.isMasterBusiness) {
        this.filters["business_id"] = filters["filter[business_id]"]?.id;
        this.filters["filter[business_id]"] = this.filters["business_id"];
      }
      this.$store.dispatch(
        "walletStore/retrieveWalletTransactions",
        this.filters
      );
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectTransaction = data;
    },
  },
};
</script>
